

import { VNode } from "@vue/runtime-core";
import { Vue, Options } from "vue-class-component";

import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { DataRequest, DataResult } from "@/shared/support/Data";
import Utility from "@/shared/support/Utility";

import EditDeviceModal from "./EditDeviceModal.vue";
import DeviceButtonCell from "./DeviceButtonCell.vue";

import { DeviceDto, DeviceGridDto } from "@/shared/models/DeviceDto";
import BoolDisplayGridCell from "@/shared/components/common/BoolDisplayGridCell.vue";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { UserRoles } from "@/identity";
import { RegionTypeDescription } from "@/shared/enums/RegionType";
import App from "@/App.vue";
import { OpsysAllType, OpsysTypeDescription } from "@/shared/enums/OpsysType";
import SelectionSupport, { SelectionEntry } from "@/shared/support/Selection";

@Options({
    components: {
        EditDeviceModal,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, show a modal (if any)
                this.navigate();
            }
        }
    }
})
export default class DeviceInventory extends Vue {

    loading = false;
    status: GenericActiveStatus = GenericActiveStatus.Active;
    selectedOpsys: OpsysAllType = OpsysAllType.All;
    opsysSelections: SelectionEntry[] = SelectionSupport.EnumSelections(OpsysAllType, OpsysTypeDescription);

    showDeviceModal(deviceGuid?: string): void {
        this.$nextTick((): void => {
            this.$refs.devicemodal.show(deviceGuid);
        });
    }
    hideDeviceModal(forceReload?: boolean, setUrl?: boolean): void {
        this.$refs.devicemodal?.hide(forceReload, setUrl);
    }
    addNew(): void {
        App.MainApp.routerPush("/devices", { deviceguid: "" });
    }
    canAddNew(): boolean {
        return UserRoles.has("AuggieDeviceCreate");
    }

    dataResult:DataResult<DeviceDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "Actions", title: " ", sortable: false, width: "120px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions },
        { field: "DeviceGuid", hidden: true },
        { field: "DeviceCalibrationUploadId", hidden: true },
        { field: "ToolKey", hidden: true },
        { field: "DeviceName", title: "Name", width: "300px", },
        { field: "DeviceSerialNumber", title: "Serial #", width: "350px", },
        { field: "DeviceClientVersion", title: "SW Version", width: "120px", },
        { field: "HardwareVersion", title: "HW Version", width: "120px", },
        { field: "Opsys__Display", title: "OpSys", width: "140px", },
        { field: "Region__Display", title: "Region", width: "120px", },
        { field: "ActiveInd", title: "Active", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActive },
        { field: "AllowAssistedCalibrationInd", title: "Assisted Calibration", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellAssistedCalibration },
        { field: "AllowAssistedTargetDisplayInd", title: "Targets", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellAssistedTargetDisplay },
        { field: "AllowSelfCalibrationInd", title: "Self Calibration", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellSelfCalibration },
        { field: "VerifiedVehiclesOnlyInd", title: "Verified Only", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellVerifiedOnly },
        { field: "CreatedDt", title: "Created", width: "180px", },
        { field: "CreatedByUserName", title: "Created By", width: "300px", },
        { field: "UpdatedDt", title: "Updated", width: "180px", },
        { field: "UpdatedByUserName", title: "Updated By", width: "300px", },
    ];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            DeviceButtonCell, { // the component used to render the cell
                deviceGuid: props.dataItem.DeviceGuid,
                deviceName: props.dataItem.DeviceName,
                active: props.dataItem.ActiveInd,
                deviceCalibrationUploadId: props.dataItem.DeviceCalibrationUploadId,
                hasCompleteSetup: (props.dataItem.AllowAssistedCalibrationInd || props.dataItem.AllowAssistedTargetDisplayInd || props.dataItem.AllowSelfCalibrationInd) && props.dataItem.DeviceCalibrationUploadId,
                hasToolKey: props.dataItem.ToolKey,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActive(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.ActiveInd,  // props to pass to the component
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellSelfCalibration(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.AllowSelfCalibrationInd,  // props to pass to the component
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellAssistedCalibration(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.AllowAssistedCalibrationInd,  // props to pass to the component
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellAssistedTargetDisplay(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.AllowAssistedTargetDisplayInd,  // props to pass to the component
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellVerifiedOnly(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.VerifiedVehiclesOnlyInd,  // props to pass to the component
            });
    }

    loadGrid(dataState: State, search: string): void {

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<DeviceGridDto[]>("/Service/Device", dataState, search,
            {
                StatusFilter: this.status,
                Opsys: this.selectedOpsys === OpsysAllType.All ? null : this.selectedOpsys
            })
            .then((result): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.CreatedDt = Utility.getFormattedDateTime(new Date(row.CreatedDt));
                    if (row.UpdatedDt) row.UpdatedDt = Utility.getFormattedDateTime(new Date(row.UpdatedDt));
                    row.DeviceSerialNumber = Utility.formatSerialNumber(row.DeviceSerialNumber);
                    row.Opsys__Display = row.Opsys ? OpsysTypeDescription[row.Opsys] : "(n/a)";
                    row.Region__Display = RegionTypeDescription[row.Region];
                }
                // set grid data
                this.loading = false;  // turn off page's loading indicator
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason): void => {
                this.loading = false; // turn off page's loading indicator
                this.dataResult = GridPanel.EmptyDataResult;
            });
    }
    reloadGrid(): void {
        this.$refs.deviceGrid.reload();
    }

    navigate(): void {
        this.hideDeviceModal(true, false);
        const query = this.$router.currentRoute.value.query;
        const deviceGuid = query.deviceguid;
        if (deviceGuid !== undefined)
            this.showDeviceModal(deviceGuid as string);
    }

    mounted(): void {
        this.navigate();
    }

    $refs!: {
        devicemodal: EditDeviceModal,
        deviceGrid: GridPanel,
    }
}

