

import { VNode } from "@vue/runtime-core";
import { Vue, Options } from "vue-class-component";

import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { DataRequest, DataResult, GridResult } from "@/shared/support/Data";

import { VehicleFilterType } from "@/shared/enums/VehicleFilterType";

import EditVehicleModal  from "./EditVehicleModal.vue";

import VehicleButtonCell from "./VehicleButtonCell.vue";
import BoolDisplayGridCell from "@/shared/components/common/BoolDisplayGridCell.vue";

import { CalibrationTypeDescription } from "@/shared/enums/CalibrationType";
import { VehicleDto, VehicleGridDto } from "@/shared/models/VehicleDto";
import Utility from "@/shared/support/Utility";
import Selection, { SelectionEntry } from "@/shared/components/common/Selection.vue";
import { UserRoles } from "@/identity";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { RegionType, RegionTypeAll, RegionTypeDescription, RegionTypeDescriptionAll } from "@/shared/enums/RegionType";
import App from "@/App.vue";

@Options({
    components: {
        EditVehicleModal,
    },
})
export default class ConfigurationVehicles extends Vue {

    loading = false;

    selectedYear: number|string = "";
    yearSelections: any = null;
    selectedMake: string = "";
    makeSelections: any = null;
    selectedModel: string = "";
    modelSelections: any = null;
    selectedRegion: number = RegionTypeAll.All;
    regionSelections = Selection.EnumSelections(RegionType, RegionTypeDescription);
    selectedStatus: number = GenericActiveStatus.Active;

    show(vehicleId?: number, clone?: string): void {
        this.$refs.vehiclemodal.show(vehicleId, clone);
    }
    hide(forceReload?: boolean, setUrl?: boolean): void {
        this.$refs.vehiclemodal.hide(forceReload, setUrl);
    }
    addNew(): void {
        App.MainApp.routerPush("/Configuration", { vehicleid: "" });
    }
    canAddNew(): boolean {
        return UserRoles.has("AuggieVehicleEdit");
    }

    dataResult:DataResult<VehicleDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "Actions", title: " ", sortable: false, width: "120px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions }, // https://v3.vuejs.org/guide/migration/render-function-api.html
        { field: "VehicleId", hidden: true },
        { field: "VehicleMake", title: "Make", width: "200px", },
        { field: "VehicleModel", title: "Model", width: "250px", },
        { field: "VehicleYear", title: "Year", width: "100px", },
        { field: "Region__Display", title: "Region", width: "120px", },
        { field: "LegLengthA", title: "Leg A (Front)", width: "150px", headerClassName: "gRight", className: "gRight", },
        { field: "LegLengthB", title: "B (Front)", width: "100px", headerClassName: "gRight", className: "gRight", },
        { field: "LegLengthC", title: "C (Rear)", width: "100px", headerClassName: "gRight", className: "gRight", },
        { field: "ActiveInd", title: "Active", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActive },
        { field: "VerifiedInd", title: "Verified", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellVerified },
    ];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            VehicleButtonCell, { // the component used to render the cell
                vehicleId: props.dataItem.VehicleId,
                vehicleName: `${props.dataItem.VehicleMake} ${props.dataItem.VehicleModel} ${props.dataItem.VehicleYear}`,
                active: props.dataItem.ActiveInd,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActive(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.ActiveInd,  // props to pass to the component
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellVerified(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.VerifiedInd,  // props to pass to the component
            });
    }

    loadGrid(dataState: State, search: string): void {

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<VehicleGridDto[]>("/Service/Vehicle", dataState, search, {
            VehicleMake: this.selectedMake ? this.selectedMake : null,
            VehicleModel: this.selectedModel ? this.selectedModel : null,
            VehicleYear: this.selectedYear ? this.selectedYear : null,
            Region: this.selectedRegion,
            StatusFilter: this.selectedStatus,
        })
            .then((result: GridResult<VehicleGridDto[]>): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.CreatedDt = Utility.getFormattedDateTime(new Date(row.CreatedDt));
                    if (row.UpdatedDt) row.UpdatedDt = Utility.getFormattedDateTime(new Date(row.UpdatedDt));
                    row.Region__Display = RegionTypeDescription[row.Region];
                }
                // set grid data
                this.loading = false;  // turn off page's loading indicator
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason): void => {
                this.loading = false; // turn off page's loading indicator
                this.dataResult = GridPanel.EmptyDataResult;
            });
    }
    reloadGrid(): void {
        this.$refs.vehicleGrid.reload();
    }
    getCalibrationTypes(row: VehicleDto): string {
        if (row.VehicleCalibrations) {
            if (row.VehicleCalibrations.length > 1) {
                return `${CalibrationTypeDescription[row.VehicleCalibrations[0].CalibrationType]}/${CalibrationTypeDescription[row.VehicleCalibrations[1].CalibrationType]}`;
            } else if (row.VehicleCalibrations.length > 0) {
                return CalibrationTypeDescription[row.VehicleCalibrations[0].CalibrationType];
            }
        }
        return "(n/a)";
    }

    makeChange():void {
        this.updateDropDownModel();
        this.updateDropDownYear(true);
    }
    modelChange():void {
        this.updateDropDownYear(true);
    }
    yearChange():void {
        this.reloadGrid();
    }
    regionChange():void {
        this.reloadGrid();
    }
    statusChange():void {
        this.reloadGrid();
    }

    created(): void {
        this.updateDropDowns(); // get all data needed to render
    }

    updateDropDownMake(): void {
        this.loading = true;

        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", { "VehicleFilterType": VehicleFilterType.VehicleMake })
            .then((options:SelectionEntry[]): void => {
                options.unshift({ Key: "", Value: "All" });
                this.makeSelections = options;
                this.selectedMake = "";
                this.loading = false;
            }).catch((e): void => {
                this.loading = false;
            });
    }
    updateDropDownModel(reloadGrid?: boolean): void {
        this.loading = true;

        const make = this.selectedMake || null;
        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", { "VehicleFilterType": VehicleFilterType.VehicleModel, VehicleMake: make })
            .then((options:SelectionEntry[]): void => {
                options.unshift({ Key: "", Value: "All" });
                this.modelSelections = options;
                this.selectedModel = "";
                this.loading = false;
                if (reloadGrid)
                    this.reloadGrid();
            }).catch((e): void => {
                this.loading = false;
            });

        this.loading = false;
    }
    updateDropDownYear(reloadGrid?: boolean): void {
        this.loading = true;

        const make = this.selectedMake || null;
        const model = this.selectedModel || null;
        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", { "VehicleFilterType": VehicleFilterType.VehicleYear, VehicleMake: make, VehicleModel: model })
            .then((options:SelectionEntry[]): void => {
                options.unshift({ Key: "", Value: "All" });
                this.yearSelections = options;
                this.selectedYear = "";
                this.loading = false;
                if (reloadGrid)
                    this.reloadGrid();
            }).catch((e): void => {
                this.loading = false;
            });
    }
    updateDropDowns(): void {

        this.updateDropDownMake();
        this.updateDropDownModel();
        this.updateDropDownYear();
    }
    mounted(): void {
        this.regionSelections.unshift({ Key: RegionTypeAll.All, Value: RegionTypeDescriptionAll });
    }

    $refs!: {
        vehiclemodal: EditVehicleModal,
        vehicleGrid: GridPanel,
    }
}

