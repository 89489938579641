

import ModalBase from "@/shared/components/common/ModalBase";
import Toast from "@/shared/support/Toast";

import ValidationMessage, { ValidationLevel } from "@/shared/components/common/ValidationMessage.vue";

import { DeviceDto } from "@/shared/models/DeviceDto";
import { DataRequest } from "@/shared/support/Data";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { UploadType } from "@/shared/enums/UploadType";
import { Options } from "vue-class-component";
import UploadFile from "@/shared/components/common/UploadFile.vue";
import { UploadDto } from "@/shared/models/UploadDto";
import { Tab } from "bootstrap";
import Utility from "@/shared/support/Utility";
import { RegionType, RegionTypeDescription } from "@/shared/enums/RegionType";
import SelectionSupport, { SelectionEntry } from "@/shared/support/Selection";
import { UserRoles } from "@/identity";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { OpsysTypeDescription } from "@/shared/enums/OpsysType";
import { LightSelectionType, LightSelectionTypeDescription } from "@/shared/enums/LightSelectionType";

@Options({
    components: {
        UploadFile,
    },
    watch: {
        active(val: boolean): void {
            this.validCalibrationSelection();
        },
    },
})
export default class DeviceModal extends ModalBase {

    deviceGuid: string = "";

    active: boolean = false;
    verifiedVehiclesOnlyInd = true;
    allowAssistedCalibrationInd = false;
    allowAssistedTargetDisplayInd = false;
    allowSelfCalibrationInd = false;
    deviceName: string = "";
    serialNumber: string = "";
    deviceCalibrationUploadId: number | null = null;
    hardwareVersion: string = "";
    toolKey: string = "";
    notes: string = "";
    detailLog: boolean = false;
    wheelArchHeightEnabledInd: boolean = true;
    selectedLights = LightSelectionType.TargetScreen;
    lightSelections = SelectionSupport.EnumSelections(LightSelectionType, LightSelectionTypeDescription);
    deviceClientVersion: string = "";
    deviceLastContact: string = "";
    deviceConfig: DeviceDto = {} as DeviceDto;
    selectedRegion: RegionType = RegionType.Domestic;
    regionSelections: SelectionEntry[] = SelectionSupport.EnumSelections(RegionType, RegionTypeDescription);
    createdDt = "";
    createdBy = "";
    updatedDt = "";
    updatedBy = "";

    isEdit = false;
    isNew = false;
    isView = false;
    hasConfig(): boolean { return !!this.deviceConfig.CameraDistortionParametersJson; }

    get serialNumberFormatted(): string {
        return Utility.formatSerialNumber(this.serialNumber);
    }
    get deviceOpsys(): string {
        if (this.deviceConfig.Opsys)
            return OpsysTypeDescription[this.deviceConfig.Opsys];
        return "";
    }

    allowedExtensions: string[] = [ "zip" ];

    deviceNameErrorMessage = "";
    versionErrorMessage = "";
    toolKeyErrorMessage = "";
    deviceNotesErrorMessage = "";
    serialNumberErrorMessage = "";
    notesErrorMessage = "";
    calibrationErrorMessage = "";

    getUploadType(): number {
        return UploadType.DeviceCalibration;
    }
    getAttachmentUploadType(): number {
        return UploadType.DeviceDocument;
    }

    initializeForm():void {
        this.deviceGuid = "";
        this.clearForm();
    }
    clearForm(): void {
        this.isNew = this.isEdit = this.isView = false;
        this.active = false;
        this.verifiedVehiclesOnlyInd = true;
        this.allowAssistedCalibrationInd = false;
        this.allowAssistedTargetDisplayInd = false;
        this.allowSelfCalibrationInd = false;
        this.deviceName = "";
        this.selectedRegion = RegionType.Domestic;
        this.serialNumber = "";
        this.hardwareVersion = "";
        this.toolKey = "";
        this.notes = "";
        this.detailLog = false;
        this.wheelArchHeightEnabledInd = true;
        this.selectedLights = LightSelectionType.TargetScreen;
        this.deviceNameErrorMessage = "";
        this.versionErrorMessage = "";
        this.notesErrorMessage = "";
        this.serialNumberErrorMessage = "";
        this.calibrationErrorMessage = "";
        this.deviceConfig = {} as DeviceDto;
        this.createdDt = "";
        this.createdBy = "";
        this.updatedDt = "";
        this.updatedBy = "";
        this.$refs.refUploadFile.clear();
        this.activateGeneralTab();
    }
    activateGeneralTab(): void {
        const tab = new Tab(this.$refs.refGeneralDeviceTag);
        tab.show();
    }

    validForm(show: ValidationLevel = ValidationLevel.Test): boolean {
        if (this.isView) return true;
        const errors: string[] = [];

        this.validDeviceName(errors, show);
        this.validVersion(errors, show);
        this.validToolKey(errors, show);
        this.validNotes(errors, show);
        this.validSerialNumber(errors, show);
        this.validCalibrationSelection(errors, show);

        if (show === ValidationLevel.Show)
            ValidationMessage.display(errors);
        return errors.length === 0;
    }
    validDeviceName(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.deviceName) return "The Device Name field is required";
            return null;
        }, (message: string):void =>{ this.deviceNameErrorMessage = message; });
    }
    validVersion(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.hardwareVersion) return "The Hardware Version field is required";
            return null;
        }, (message: string):void =>{ this.versionErrorMessage = message; });
    }
    validToolKey(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.toolKey) return null;
            const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            if (!pattern.test(this.toolKey)) return "The Inventory Tool Key is invalid.";
            return null;
        }, (message: string):void =>{ this.toolKeyErrorMessage = message; });
    }
    validNotes(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            return null;
        }, (message: string):void =>{ this.notesErrorMessage = message; });
    }
    validSerialNumber(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.isNew && !this.serialNumber) return "The Serial Number field is required";
            return null;
        }, (message: string):void =>{ this.serialNumberErrorMessage = message; });
    }
    validCalibrationSelection(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active && !this.allowAssistedCalibrationInd && !this.allowAssistedTargetDisplayInd && !this.allowSelfCalibrationInd) return "At least one calibration selection is required";
            return null;
        }, (message: string):void =>{ this.calibrationErrorMessage = message; });
    }

    show(deviceGuid?: string): void {
        this.initializeForm(); // in case this is a second invocation of this modal we need to clear all
        this.deviceGuid = deviceGuid || "";
        if (!this.deviceGuid) {
            this.isNew = true;
        } else {
            this.isEdit = !!this.deviceGuid && UserRoles.has("AuggieDeviceEdit");
            if (!this.isEdit)
                this.isView = !!this.deviceGuid && UserRoles.has("AuggieDeviceView");
        }
        this.allowAssistedCalibrationInd = this.isNew;
        this.showModal();
        this.loadDevice();
    }
    hide(forceReload?: boolean, setUrl?: boolean):void {
        if (this.hideModal(forceReload) && (setUrl === undefined || setUrl))
            this.$router.push(window.location.pathname);
    }
    saveClicked(): void {
        if (!this.validForm(ValidationLevel.Show))
            return;
        this.verifyToolKey();
    }
    cancelClicked():void {
        this.hide();
    }

    verifyToolKey(): void {
        if (this.toolKey && this.active) {
            // we're saving a toolkey for an active device
            this.loading = true;
            // check if any other active devices use this toolkey
            const dr = new DataRequest();
            dr.$get<DeviceDto[]>("/Service/Device/All", {
                "ToolKey": this.toolKey,
                "ExcludeDeviceGuid": this.deviceGuid ? this.deviceGuid : null,
                "Active": true,
            })
                .then((otherDevices: DeviceDto[]): void => {
                    this.loading = false;
                    if (otherDevices.length > 0) {
                        // There is a device using this toolkey
                        const otherDev = otherDevices[0];
                        confirmYesNo(`This Inventory Tool Key is already used by active device ${otherDev.DeviceName}. By proceeding, device ${otherDev.DeviceName} will be deactived. Are you sure you want to save this device?`)
                            .then((clicked: ButtonClicked) => {
                                if (clicked === ButtonClicked.Ok) {
                                    this.saveDevice();
                                }
                            });
                    } else {
                        this.saveDevice();
                    }
                })
                .finally((): void => {
                    this.loading = false;
                });
        } else {
            this.saveDevice();
        }
    }

    loadDevice(): void {

        if (this.isNew) {
            this.initialized();
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<DeviceDto>(`/Service/Device/${this.deviceGuid}`)
            .then((device: DeviceDto): void => {
                this.loading = false;
                this.initialized();
                if (device) {
                    this.deviceGuid = device.DeviceGuid;
                    this.deviceName = device.DeviceName;
                    this.selectedRegion = device.Region;
                    this.serialNumber = device.DeviceSerialNumber;
                    this.deviceCalibrationUploadId = device.DeviceCalibrationUploadId;
                    this.toolKey = device.ToolKey || "";
                    this.hardwareVersion = device.HardwareVersion;
                    this.notes = device.Notes;
                    this.detailLog = device.DetailLog;
                    this.wheelArchHeightEnabledInd = device.WheelArchHeightEnabledInd;
                    this.selectedLights = device.LightIntensity;
                    this.active = device.ActiveInd;
                    this.verifiedVehiclesOnlyInd = device.VerifiedVehiclesOnlyInd;
                    this.allowAssistedCalibrationInd = device.AllowAssistedCalibrationInd;
                    this.allowAssistedTargetDisplayInd = device.AllowAssistedTargetDisplayInd;
                    this.allowSelfCalibrationInd = device.AllowSelfCalibrationInd;
                    this.deviceClientVersion = device.DeviceClientVersion || "";
                    this.deviceLastContact = device.DeviceLastContact ? Utility.getFormattedDateTime(new Date(device.DeviceLastContact)) : "";
                    this.deviceConfig = device;
                    this.createdDt = Utility.getFormattedDateTime(new Date(device.CreatedDt));
                    this.createdBy = device.CreatedByUserName;
                    if (device.UpdatedDt) {
                        this.updatedDt = Utility.getFormattedDateTime(new Date(device.UpdatedDt));
                        this.updatedBy = device.UpdatedByUserName || "";
                    }
                } else {
                    Toast.error(`Unable to retrieve Device ID ${this.deviceGuid}`);
                }
            })
            .catch((reason): void => {
                this.loading = false;
                this.initialized();
            });
    }
    saveDevice(): void {
        this.loading = true;

        const dr = new DataRequest();

        const device: Partial<DeviceDto> = {
            DeviceGuid: this.isEdit ? this.deviceGuid : undefined,
            DeviceName: this.deviceName,
            Region: this.selectedRegion,
            HardwareVersion: this.hardwareVersion,
            DeviceSerialNumber: this.serialNumber,
            DeviceCalibrationUploadId: this.deviceCalibrationUploadId,
            ToolKey: !this.toolKey ? null : this.toolKey,
            Notes: this.notes,
            DetailLog: this.detailLog,
            WheelArchHeightEnabledInd: this.wheelArchHeightEnabledInd,
            LightIntensity: this.selectedLights,
            ActiveInd: this.isEdit && this.hasConfig() && this.active,
            VerifiedVehiclesOnlyInd: this.verifiedVehiclesOnlyInd,
            AllowAssistedCalibrationInd: this.allowAssistedCalibrationInd,
            AllowAssistedTargetDisplayInd: this.allowAssistedTargetDisplayInd,
            AllowSelfCalibrationInd: this.allowSelfCalibrationInd,
        };

        dr.$post<Partial<DeviceDto>, DeviceDto>("/Service/Device", null, device)
            .then((device: DeviceDto): void => {
                this.loading = false;
                if (device.UpdateResult.Success) {
                    if (this.isEdit && this.hasConfig())
                        this.hide(true);
                    else if (this.isNew) {
                        this.isNew = false;
                        this.isEdit = true;
                    }
                    this.deviceGuid = device.DeviceGuid;
                    this.deviceName = device.DeviceName;
                    this.selectedRegion = device.Region;
                    this.serialNumber = device.DeviceSerialNumber;
                    this.deviceCalibrationUploadId = device.DeviceCalibrationUploadId;
                    this.toolKey = device.ToolKey || "";
                    this.hardwareVersion = device.HardwareVersion;
                    this.notes = device.Notes;
                    this.detailLog = device.DetailLog;
                    this.wheelArchHeightEnabledInd = device.WheelArchHeightEnabledInd;
                    this.selectedLights = device.LightIntensity;
                    this.active = device.ActiveInd;
                    this.verifiedVehiclesOnlyInd = device.VerifiedVehiclesOnlyInd;
                    this.allowAssistedCalibrationInd = device.AllowAssistedCalibrationInd;
                    this.allowAssistedTargetDisplayInd = device.AllowAssistedTargetDisplayInd;
                    this.allowSelfCalibrationInd = device.AllowSelfCalibrationInd;
                    this.deviceConfig = device;
                    this.createdDt = Utility.getFormattedDateTime(new Date(device.CreatedDt));
                    this.createdBy = device.CreatedByUserName;
                    if (device.UpdatedDt) {
                        this.updatedDt = Utility.getFormattedDateTime(new Date(device.UpdatedDt));
                        this.updatedBy = device.UpdatedByUserName || "";
                    }
                    if (device.UpdateResult.Message)
                        Toast.success(device.UpdateResult.Message);
                } else {
                    this.deviceCalibrationUploadId = null;
                    this.$refs.refUploadFile?.clear();
                    confirmOk(device.UpdateResult.Message || "Device save failed!");
                }
            })
            .catch((reason): void =>{
                this.loading = false;
            });
    }

    updateConfig(fileName: string, upload: UploadDto): void {
        this.deviceCalibrationUploadId = upload.UploadId;
    }

    $refs!: {
        refUploadFile: UploadFile;
        refGeneralDeviceTag: HTMLButtonElement;
    }
}

