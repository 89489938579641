

import { Options } from "vue-class-component";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import { Global } from "@/support/GlobalData";
import SessionBase from "./SessionBase";
import DeviceStatusModal from "./DeviceStatusModal.vue";
import App from "@/App.vue";
import StatusMessage from "./StatusMessage.vue";
import { confirmYesWaitNo } from "@/shared/components/common/AlertDialog.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import ImageFooter from "./ImageFooter.vue";
import Numeric from "@/shared/components/common/Numeric.vue";
import ValidationMessage, { ValidationLevel } from "@/shared/components/common/ValidationMessage.vue";
import SessionSidePanel from "./SessionSidePanel.vue";

@Options({
    components: {
        DeviceStatusModal,
        SessionSidePanel,
        StatusMessage,
        ImageFooter,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, re-initialize
                this.initialize();
            }
        }
    }
})
export default class WindshieldAngle extends SessionBase {

    windshieldAngle: number|string = "";
    windshieldAngleErrorMessage = "";

    resetData(): void {
        this.windshieldAngle = "";
        this.windshieldAngleErrorMessage = "";
    }

    validFormRestart(show: ValidationLevel = ValidationLevel.Test): boolean {
        const errors: string[] = [];

        this.validWindshieldAngle(errors, show);

        if (show === ValidationLevel.Show)
            ValidationMessage.display(errors);
        return errors.length === 0;
    }
    validWindshieldAngle(errors?: string[], show?: ValidationLevel): void {
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.windshieldAngle !== "") {
                if (!this.$refs.refWindshieldAngle.isValid()) return "The Windshield Angle field is not valid";
                try {
                    const angle = Number(this.windshieldAngle);
                    if (angle < 16 || angle > 99) return "The Windshield Angle field is invalid";
                } catch (e) {
                    return "The Windshield Angle field is not valid";
                }
            } else {
                return "The Windshield Angle field is required";
            }
            return null;
        }, (message: string):void => { this.windshieldAngleErrorMessage = message; });
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    restartQuitClicked(): void {
        CalibrationEventSupport.sendEvent(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.CalibrationFailed);
        App.MainApp.routerReplace("/connect");
    }
    restartContinueClicked(): void {
        if (!this.validFormRestart(ValidationLevel.Show))
            return;
        Global.CalibrationSession!.WindshieldOverride = Number(this.windshieldAngle);
        CalibrationEventSupport.RetryableEvent((): void => {
            CalibrationEventSupport.sendEventCalibrationRestart(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.RestartCalibration, Number(this.windshieldAngle));
            // If all goes well we will get the device status, which continues on the desired page.
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to continue with the windshield angle change and try again? Responding No will cancel the session.");
        }, (): void => {
            App.MainApp.routerReplace("/connect");
        });
    }

    initialize(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        this.loading = true;
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            this.$refs.refDeviceStatusModal.verifySessionFromContext(false, ():void => {
                this.windshieldAngle = Global.Vehicle!.WindshieldAngle;
                if (Global.CalibrationSession!.WindshieldOverride)
                    this.windshieldAngle = Global.CalibrationSession!.WindshieldOverride;
                this.$nextTick((): void => {
                    this.$refs.refSessionSidePanel?.Update();
                });
                this.loading = false;
            });
        });
    }
    mounted(): void {
        this.initialize();
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();
    }

    $refs!: {
        refSessionSidePanel: SessionSidePanel,
        refDeviceStatusModal: DeviceStatusModal,
        refWindshieldAngle: Numeric;
    }
}

