

import { CalibrationTypeDescription } from "@/shared/enums/CalibrationType";
import { MarkerType, MarkerTypeDescription } from "@/shared/enums/MarkerType";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { Global } from "@/support/GlobalData";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class VehiclePanel extends Vue {

    @Prop({ default: null, required: true }) readonly vehicle: VehicleDto | null = null;
    @Prop({ default: null, required: true }) readonly calibration: VehicleCalibrationDto | null = null;
    @Prop({ default: "", required: false }) readonly vehicleVin: string = "";
    @Prop({ default: false }) readonly ymmOnly: boolean = false;

    model: VehicleDto|null = this.vehicle;
    calibrationModel: VehicleCalibrationDto|null = this.calibration;

    get vehicleMake(): string { return this.model?.VehicleMake || ""; }
    get vehicleModel(): string { return this.model?.VehicleModel || ""; }
    get vehicleYear(): number|null { return this.model?.VehicleYear || null; }

    get vehicleLegA(): number|string { return this.model?.LegLengthA || ""; }
    get vehicleLegB(): number|string { return this.model?.LegLengthB || ""; }
    get vehicleLegC(): number|string { return this.model?.LegLengthC || ""; }
    get windshieldAngle(): number|string { return this.model?.WindshieldAngle || ""; }
    get cameraMountingOffset(): number|string { return this.model?.CameraMountingOffset  || ""; }
    get arucoMarker(): string { return MarkerTypeDescription[this.model?.Marker || MarkerType.Standard]; }

    get calibrationType(): string {
        const calType = this.calibrationModel?.CalibrationType;
        if (!calType) return "";
        return CalibrationTypeDescription[calType];
    }

    public Update(): void {
        this.model = Global.Vehicle || null;
        this.calibrationModel = Global.VehicleCalibration || null;
    }
}

